const getIP = () => {
  let { origin } = window.location;
  if (origin.includes("http://localhost")) {
    //REPLACE the hostname below to change which server GUI makes API calls to...
    return "https://dev1.ngagecpaas.com";
    // return "https://autocpaas.comviva.com";
    //return "https://omocpaas.comviva.com";
    // return "http://emailcpaas.comviva.com:32606";
  } else {
    return origin;
  }
};

export default getIP;
