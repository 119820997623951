import { useQuery } from "react-query";
import paymentTimerIcon from "../assets/img/PaymentTimerImg.png";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchPaymentStatus,
  finalizationAPIforEAnd,
} from "../lib/payment-provider-apis";
import { useState } from "react";
import { useEffect } from "react";
import { t } from "i18next";

export default function PaymentTimer() {
  const location = useLocation();
  const timerDuration = parseInt(location?.state?.duration);

  const url = location?.state?.url;
  const token = location?.state?.token;
  const [enabled, setEnabled] = useState(url !== "" || url !== undefined);
  const navigate = useNavigate();

  useEffect(() => {
    if (!location?.state?.duration) {
      navigate("/app/header/wallets", { state: { status: "" } });
    }
  });

  const { data } = useQuery({
    queryKey: ["product"],
    enabled: enabled,
    queryFn: () =>
      url.includes("eand/finalization")
        ? finalizationAPIforEAnd(
            location?.state?.txnId ||
              window.localStorage.getItem("latest_transaction_id"),
            token
          )
        : fetchPaymentStatus(url, token, location?.state?.txnId),
    refetchInterval: 5000,
    onSuccess: (res) => {
      let status = res?.data?.response?.status;
      if (status === "Pending" || status === "Unknown") {
        setEnabled(true);
      } else {
        setEnabled(false);
        navigate("/app/header/wallets", { state: { status, url } });
      }
    },
    onError: () => {
      setEnabled(true);
      navigate("/app/header/wallets", { state: { status: "Failed", url } });
    },
  });

  return (
    <div className="flex flex-col justify-center items-center h-[99vh]">
      <img src={paymentTimerIcon} alt="Timer Icon" className="h-60 w-60" />
      <h1 className="text-3xl">{t("_Processing_your_payment...")}</h1>
      <h3 className="text-2xl mt-6 mb-8">
        {t(
          "_Do_not_refresh_this_page,_You_will_be_redirected_after_the_transaction_is_complete."
        )}
      </h3>
      <CountdownCircleTimer
        isPlaying
        duration={timerDuration}
        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
        colorsTime={[7, 5, 2, 0]}
        onComplete={() => {
          setEnabled(false);
          if (!url) {
            navigate("/app/header/wallets", {
              state: { status: "Completed", url },
            });
          } else {
            navigate("/app/header/wallets", {
              state: { status: "TimedOut", url },
            });
          }
        }}
      >
        {({ remainingTime }) => remainingTime}
      </CountdownCircleTimer>
    </div>
  );
}
